import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import goTo from 'vuetify/es5/services/goto'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Best DJ Central Florida (2021) - Pozzyvibes',
      metaTags: [
        {
          name: 'description',
          content: 'Pozzyvibes provides mobile DJ Services for weddings, community events, school dances, Free Sunset Zumba classes, and more! DJ services are available in Florida and Colorado!'
        },
        {
          name: 'Keywords',
          content: 'DJ, DJ services, MC, Master Ceremonies, mobile DJ, wedding, events, event, Colorado DJ, Florida, Florida DJ, school dance, wedding dj, music, dj, dance, disc jockey, zumba, school, pozzyvibes, mobile dj, parks, churches, a list djs, Volusia, Port Orange, Daytona Beach, Daytona, Ormond Beach, South Daytona, Wilbur by the Sea, New Smyrna Beach, DeLand, Ponce Inlet, Orlando, Holy Hill, Daytona Beach Shores, Ormond by the sea, Deltona, DeBary, Edgewater, Flagler Beach, Crescent City, Horizon Elementary, Riverwalk Park, Adult Center, Zumba, sunset, free sunset Zumba, chamber of commerce, school dances in colorado, colorado wedding djs, birthday party djs colorado, professional djs of colorado, gay weddings dj, prom dj, wedding dj denver co, red rocks dj, event planning colorado, dj co, zumba brush colorado, zumba port orange florida, community events dj, community events planner, local dj, local colorado dj, traveling dj, party dj colorado, community, events in colorado, free events in colorado, DJ Denver Co, mobile dj services in denver, best djs in denver co, affordable, affordable dj services, colorado, best mobile disc jockey, rocky mountains, weddings, dances, family events, video presentations, karaoke, lights, screen, fog machine, Denver, djs in colorado, arvada, broomfield, fort morgan dj, fort morgan, wheatridge, brush, thornton, wheat ridge, commerce city, lakewood, golden, brighton, northglenn, englewood, centennial, greenwood village, morrison, aurora, littleton, denver metro area dj, best of daytona, community choice awards, community choice awards 2023, daytona beach community choice awards, best wedding dj, best dj, daytona beach news journal, daytona beach news journal awards, city of port orange, riverwalk park, tanger mall dj, city center dj, oceanwalk dj'
        },
        {
          property: 'og:title',
          content: 'Pozzyvibes Family Friendly DJ' 
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: {
      title: 'About Pozzyvibes',
      metaTags: [
        {
          property: 'og:title',
          content: 'About Pozzyvibes' 
        }
      ]
    }
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import('../views/Team.vue'),
    meta: {
      title: 'Pozzyvibes Team',
      metaTags: [
        {
          property: 'og:title',
          content: 'Pozzyvibes Team' 
        }
      ]
    }
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('../views/Events.vue'),
    meta: {
      title: 'Pozzyvibes Events',
      metaTags: [
        {
          property: 'og:title',
          content: 'Pozzyvibes Event' 
        }
      ] 
    },
  },
  {
    path: '/story',
    name: 'Story',
    component: () => import('../views/Story.vue'),
    meta: {
      title: 'The Pozzyvibes Story',
      metaTags: [
        {
          property: 'og:title',
          content: 'The Pozzyvibes Story' 
        }
      ]
    }
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: () => import('../views/Reviews.vue'),
    meta: {
      title: 'Pozzyvibes Reviews',
      metaTags: [
        {
          property: 'og:title',
          content: 'Pozzyvibes Reviews' 
        }
      ]
    }
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
    meta: {
      title: 'Contact Pozzyvibes',
      metaTags: [
        {
          property: 'og:title',
          content: 'Contact Pozzyvibes' 
        }
      ]
    }
  },
  {
    path: '/Services',
    name: 'Services',
    component: () => import('../views/Services.vue'),
    meta: {
      title: 'Pozzyvibes Services',
      metaTags: [
        {
          property: 'og:title',
          content: 'Pozzyvibes Services' 
        }
      ]
    }
  }
]

const router = new VueRouter({
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router
