<template>
  <div class="home">
    <PozzyHome/>
  </div>
</template>

<script>
// @ is an alias to /src
import PozzyHome from '@/components/Home.vue'

export default {
  name: 'Home',
  components: {
    PozzyHome
  }
}
</script>
