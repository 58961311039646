<template>
  <v-app>
    <v-system-bar color="black darken-3"></v-system-bar>
    <v-app-bar
        color="#ff00d8"
        dark
        absolute
        dense
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img
          :src="require('./assets/pozzylogo.png')"
          max-height="25"
          max-width="25"
      />
      <v-btn
        to="/"
        plain>Pozzyvibes</v-btn>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        temporary
        absolute
    >
      <v-list
          shaped
          nav
          dense
      >
        <v-subheader>
          <v-img
              :src="require('./assets/pozzylogo.png')"
              max-height="12"
              max-width="12"
          />
          Pozzyvibes
        </v-subheader>
        <v-list-item-group
            v-model="selectedItem"
            color="primary"
        >
          <v-list-item link to="/">
            <v-list-item-content>
              <v-list-item-title class="title">
                Home
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/events">
            <v-list-item-content>
              <v-list-item-title class="title">
                Events
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/services">
            <v-list-item-content>
              <v-list-item-title class="title">
                Services
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list>
            <v-list-group
                v-for="item in aboutItems"
                :key="item.title"
                v-model="item.active"
                no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="title">{{item.title}}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  v-for="child in item.items"
                  :key="child.title"
                  :to="child.link"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-list>
            <v-list-group
                v-for="item in shopItems"
                :key="item.title"
                v-model="item.active"
                no-action
                color="primary"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="title">{{item.title}}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  v-for="child in item.items"
                  :key="child.title"
                  :href="child.link"
                  target="_blank"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-list>
            <v-list-group
                v-for="item in contactItems"
                :key="item.title"
                v-model="item.active"
                no-action
                color="primary"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="title">{{item.title}}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  v-for="child in item.items"
                  :key="child.title"
                  :href="child.link"
                  :target="child.target"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-list-item-group>
      </v-list>

    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer
        color="black lighten-1"
        padless
    >
      <v-row
          justify="center"
          no-gutters
      >
        <!--<v-btn
            v-for="link in links"
            :key="link"
            color="white"
            text
            rounded
            class="my-2"
        >
          {{ link }}
        </v-btn>-->
        <v-col
            class="gray lighten-2 py-4 text-center white--text"
            cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>© Pozzyvibes LLC. All rights reserved</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
// import HelloWorld from './components/Home';

export default {
  name: 'App',

  // components: {
  //   HelloWorld,
  // },

  data: () => ({
    drawer: false,
    group: null,
    shopItems: [
      {
        items: [
          {title: 'Amazon', link: 'https://www.amazon.com/s?k=Pozzyvibes&ref=nb_sb_noss'},
          {title: 'Redbubble', link: 'https://www.redbubble.com/people/Pozzyvibes/shop'},
          {title: 'Zazzle', link: 'https://www.zazzle.com/store/pozzyvibes'},
        ],
        title: 'Shop',
        active: true,
      },
    ],
    aboutItems: [
      {
        active: true,
        items: [
          { title: 'Our Story', link: "/story"},
          { title: 'Reviews', link: "/reviews"},
          { title: 'Pozzy Team', link: "/team" },
        ],
        title: 'About',
      }
    ],
    contactItems: [
      {
        items: [
          {title: 'Request a Consultation', link: 'https://docs.google.com/forms/d/e/1FAIpQLSer3N_fTt7nytwCJKNaUsNFa83XO6unRBgAYiRiY7v1BQ4Iig/viewform?usp=sf_link', target: "_blank"},
          {title: 'Charities and Nonprofits', link: 'https://docs.google.com/forms/d/e/1FAIpQLSfA9jdmK4cgpyBlWHllMELGVjTJ2Yvex9bDguRrPvclFDOtRg/viewform?usp=sf_link', target: "_blank"},
          {title: 'Internship Opportunities', link: 'https://docs.google.com/forms/d/e/1FAIpQLSdfQUAhLe8drtqlBlEel-nDha6jlTZFEhKuAHvNIuZ4x5VU4g/viewform?usp=sf_link', target: "_blank"},
          {title: 'Sponsorship Opportunities', link: 'https://docs.google.com/forms/d/e/1FAIpQLSdlIgJ4L_e4S713Pp17mNOT3AMeQwQhKDrwpn6X_13amZ0ODA/viewform?usp=sf_link', target: "_blank"},
          {title: 'Volunteer Opportunities', link: 'https://docs.google.com/forms/d/e/1FAIpQLSejHVO5e0k3fChfsS8jWlstYbGomMAsyujRsterrxvvgRai0g/viewform?usp=sf_link', target: "_blank"},
          {title: 'Contact Us', link: '/#/contact', target: ""},
        ],
        title: 'Contact',
        active: true,
      },
    ],
    "selectedItem": 1,
  }),
};
</script>
